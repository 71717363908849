import React from "react";

const GoogleMap = () => {
  return (
    <div className="google-map">
      <iframe
        title="Office Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6787843.833301686!2d-127.2755646!3d33.8283903!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcccd62d59a075%3A0xb1f2c2bf6698d29c!2sNational%20Wood%20Products%20Inc.!5e0!3m2!1sen!2sph!4v1706246660210!5m2!1sen!2sph"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;